<template>
  <v-container fluid>
    
    <v-tabs v-model="tab" fixed-tabs show-arrows>
      <v-tab class="ordemArea" :to="`/areas/${$route.params.id}/editar`"> Area </v-tab>

      <v-tab class="ordemBox"> Box </v-tab>

      <tab-rotas-tabelas class="ordemTabela" :temPermissao="
        $store.getters['usuario/temPermissao']('listar.areas.tabelas')
      " :to="`/areas/${$route.params.id}/tabela`">
      </tab-rotas-tabelas>

      <tab-rotas-convenios class="ordemConvenio" :temPermissao="
        $store.getters['usuario/temPermissao']('listar.areas.convenios')
      " :to="`/areas/${$route.params.id}/convenio`">
      </tab-rotas-convenios>

      <tab-rotas-tipos-pagamentos class="ordemPagamento" :to="`/areas/${$route.params.id}/tipos-pagamentos`"
        :temPermissao="
          $store.getters['usuario/temPermissao']('listar.areas.tipospagamentos')
        ">
      </tab-rotas-tipos-pagamentos>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item> </v-tab-item>
      <v-tab-item>
        <v-card-actions>
          <v-row class="mt-1">
            <v-col>
              <ButtonAdicionar :temPermissao="
                $store.getters['usuario/temPermissao']('inserir.boxes')
              " :to="`../${$route.params.id}/box/adicionar`" />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-row class="mt-4">
          <v-col>
            <v-data-table disable-filtering disable-sort :server-items-length="totalRegistros" :headers="cabecalho"
              :items="items" :options.sync="paginacao" :footer-props="{
                'items-per-page-options': [10, 20, 50],
              }" @update:options="carregarBoxEmpresas">
              <template v-slot:[`item.actions`]="{ item }" class="text-right">
                <table-btn-mostrar :temPermissao="
                  $store.getters['usuario/temPermissao']('mostrar.boxes')
                " :href="`/areas/${item.area_id}/box/${item.id}/mostrar`" />
                <table-btn-editar :temPermissao="
                  $store.getters['usuario/temPermissao']('editar.boxes')
                " :href="`/areas/${item.area_id}/box/${item.id}/editar`" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item> </v-tab-item>
      <v-tab-item> </v-tab-item>
      <v-tab-item> </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import areas from "@/api/areas";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
      tab: 1,
     
      totalRegistros: 0,
      idExcluir: null,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "descricao" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      items: [],
      paginacao: {},
    };
  },

  methods: {
    async carregarBoxEmpresas() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const area_id = this.$route.params.id;

        const resposta = await areas.listarAreasBox(area_id, {
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
        });

        this.items = resposta.data.data;
        this.items.forEach((item) => {
          if (item.ativo == 1) {
            item.status = "Ativo";
          } else {
            item.status = "Inativo";
          }
        });

        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>

<style>
.ordemArea {
  order: -1;
}

.ordemBox {
  order: 3;
}

.ordemTabela {
  order: 0;
}

.ordemConvenio {
  order: 3;
}

.ordemPagamento {
  order: 4;
}
</style>

